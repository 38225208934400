import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  showPageHeader: boolean = true
  showPageFooter: boolean = true

  constructor(
    private activatedroute: ActivatedRoute
  ) {
    this.activatedroute.data.subscribe((data : any) => {
      this.showPageHeader = data.showPageHeader;
      this.showPageFooter = data.showPageFooter;
    })
  }

  ngOnInit(): void {
  }

}
