import { Component, OnInit, Input } from '@angular/core';
import { Img } from './img.type';

@Component({
  selector: 'app-img',
  templateUrl: './img.component.html',
  styleUrls: ['./img.component.scss']
})
export class ImgComponent {

  @Input() loader:string='assets/images/Loader.gif';
  // @Input() loader:string='https://media.giphy.com/media/ycfHiJV6WZnQDFjSWH/giphy.gif';

  @Input() config : Img = {
    image: '',
    alt: "Image",
    width: 0,
    height: 0,
    cover: false,
    contain: false,
    circle: false,
    padding: 0,
    background: '#fff'
  };

  isLoading:boolean;
  showSuccessImage : boolean = false
  showErrorImage : boolean = false
  errorImage: string = 'assets/images/broken-image.png'

  constructor() {
    this.isLoading=true;
  }

  hideLoader(){
    this.isLoading=false;
  }

  showImage(){
    this.isLoading=false;
    this.showSuccessImage = true
    this.showErrorImage = false
  }

  showError(){
    this.isLoading = false
    this.showSuccessImage = false
    this.showErrorImage = true
  }

}
