import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HelperService } from '../helper/helper.service';
import { User } from 'src/app/models/user.model';
import { ApiRequest } from 'src/app/models/api-request.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userSubject: ReplaySubject<any> = new ReplaySubject<any>(1);
  userData: User = {} as User;

  /**
   * Constructor
   */
  constructor(
    private httpClient: HttpClient,
    private helperService: HelperService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: User) {
    // Store the value
    this.userData = value;
    this.userSubject.next(value);
  }

  get user$(): Observable<User> {
    return this.userSubject.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  get(): Observable<ApiRequest> {
    return this.httpClient
      .get<ApiRequest>(this.helperService.getApiUrl() + 'user')
      .pipe(
        tap((response: ApiRequest) => {
          this.userData = response.data;
          this.userSubject.next(this.userData);
        })
      );
  }

  /**
   * Update the user
   *
   * @param user
   */
  update(user: any): Observable<any> {
    return this.httpClient.patch(
      this.helperService.getApiUrl() + 'user/profile',
      user
    );
  }

   /**
   * Update the user
   *
   * @param user
   */
   updateKyc(user: any): Observable<any> {
    return this.httpClient.patch(
      this.helperService.getApiUrl() + 'user/kyc',
      user
    );
  }

  /**
   * Update the user profile photo
   *
   * @param url
   */
  updateAvatar(data: FormData) {
    return this.httpClient.patch(
      this.helperService.getApiUrl() + 'user/profile/photo',
      data
    );
  }

  findOne(id: string): Observable<ApiRequest> {
    return this.httpClient.get<ApiRequest>(
      this.helperService.getApiUrl() + `user/${id}`
    );
  }

  remove(data: any): Observable<ApiRequest> {
    return this.httpClient.patch<ApiRequest>(
      this.helperService.getApiUrl() + 'user/remove',
      data
    );
  }

  suspend(data: any): Observable<ApiRequest> {
    return this.httpClient.patch<ApiRequest>(
      this.helperService.getApiUrl() + 'user/suspend',
      data
    );
  }

  activate(data: any): Observable<ApiRequest> {
    return this.httpClient.patch<ApiRequest>(
      this.helperService.getApiUrl() + 'user/activate',
      data
    );
  }

  clearLimit(data: any): Observable<ApiRequest> {
    return this.httpClient.patch<ApiRequest>(
      this.helperService.getApiUrl() + 'user/clear-limit',
      data
    );
  }

  resetPassword(data: any): Observable<ApiRequest> {
    return this.httpClient.patch<ApiRequest>(
      this.helperService.getApiUrl() + 'user/reset-password',
      data
    );
  }
}
