import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HelperService } from '../helper/helper.service';
import { Observable } from 'rxjs';
import { ApiRequest } from 'src/app/models/api-request.model';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  constructor(
    private httpClient: HttpClient,
    private helperService: HelperService
  ) {}

  initialize(walletId: string): Observable<ApiRequest> {
    return this.httpClient.patch<ApiRequest>(
      this.helperService.getApiUrl() + 'transactions/initialize',
      { walletId }
    );
  }

  find(): Observable<ApiRequest> {
    return this.httpClient.get<ApiRequest>(
      this.helperService.getApiUrl() + 'transactions/user'
    );
  }

  findOne(id: string): Observable<ApiRequest> {
    return this.httpClient.get<ApiRequest>(
      this.helperService.getApiUrl() + `transactions/${id}`
    );
  }
}
