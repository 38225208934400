<div *ngIf="transactions.length == 0" class="box box-100 box-min">
  <app-not-found
    [icon]="'no-transaction.svg'"
    [title]="'No transactions to show'"
    [subtitle]="'All transactions carried out on Didalla would show here.'"
  ></app-not-found>
</div>
<div *ngIf="transactions.length > 0">
  <div class="transaction-wrapper">
    <div class="box box-100 box-min">
      <h5 class="mb-5" *ngIf="title">{{ title }}</h5>
      <a
        [routerLink]="'/transactions/view/' + trx.id"
        routerLinkActive="router-link-active"
        *ngFor="let trx of transactions"
        class="transaction mb-3"
      >
        <div class="left">
          <div class="icon">
            {{ getInitials(trx.beneficiary.accountName) | uppercase }}
          </div>
          <div class="details">
            <h6 class="name">{{ trx.beneficiary.accountName }}</h6>
            <h6 class="date">{{ trx.createdAt | date }}</h6>
            <h6 class="status">
              <app-status-dsp [status]="trx.status"></app-status-dsp>
            </h6>
          </div>
        </div>
        <div class="right">
          <h6 class="price">{{ trx.currency }} {{ trx.amount | num }}</h6>
          <h6 class="price"><small>{{ trx.withdrawalCurrency }} {{ trx.withdrawalAmount | num }}</small></h6>
        </div>
      </a>
    </div>
  </div>
</div>
