// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // api: {
  // 	version: '1.0',
  //   url: 'http://localhost:3000/',
  // },
  // payment: {
  //   stripe: {
  //     public_key: 'pk_test_51IvSPMBq5sNbHqP6J56AYkbuw7SrA2sybQFBhs4Nf5WOg3nwFlJVTn4Wc18lzr55T2GVBoEuwc7mUnCIfEPMKK6T00x05Amhvw'
  //   }
  // },
  payment: {
    stripe: {
      public_key: 'pk_live_51IvSPMBq5sNbHqP6k2RtNowbHg9LbfrzyKvWHmbKuy67r2klU7bhXeiQ4qq4buaZ3SQhpuh4mkpJKPwTbbG7s2kT00Dp4Mottc'
    }
  },
  api: {
  	version: '1.0',
    url: 'https://api.didalla.com/',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
