import { Component, Input, OnInit } from '@angular/core';
import { HelperService } from 'src/app/core/helper/helper.service';
import { TransactionDetailsComponent } from '../../modules/transaction/components/transaction-details/transaction-details.component';
import { TransactionService } from 'src/app/core/transaction/transaction.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
})
export class TransactionsComponent implements OnInit {
  @Input() title: any = null;
  @Input() transactions: any[] = [];

  constructor(
    private helperService: HelperService,
    private transactionService: TransactionService
  ) {}

  ngOnInit(): void {}

  viewTransaction(trx: any) {
    this.helperService.loadModal(
      TransactionDetailsComponent,
      'Transaction details',
      '',
      trx
    );
  }

  getInitials(accountName: string) {
    let splittedName = accountName.split(' ');
    splittedName = splittedName.filter((item: string) => item !== '');
    if (splittedName.length > 1) {
      return splittedName[0][0] + splittedName[1][0];
    } else {
      return splittedName[0] + splittedName[1];
    }
  }
}
