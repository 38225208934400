import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HelperService } from 'src/app/core/helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(
    private helperService: HelperService,
    private http: HttpClient,
    ) { }

  upload(data: any){
    return this.http.post(
      this.helperService.getApiUrl()+'misc/upload',
      data,
      {headers: this.helperService.header()}
    );
  }
}
