<label for="productId">{{ label }}</label>
<div class="form-wrapper">
  <input
    [disabled]="disable"
    (focus)="activateAutocomplete()"
    (blur)="validateProductValue()"
    [matAutocomplete]="auto"
    type="text"
    [(ngModel)]="value"
    (keyup)="valueChanged($event)"
    [placeholder]="placeholder"
  />
  <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
    <mat-option
      *ngFor="let item of filteredOptions"
      [value]="item[display]"
      style="font-size: 0.8em"
    >
      {{ item[display] }}
    </mat-option>
  </mat-autocomplete>
</div>
