/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Directive,
  Output,
  EventEmitter,
  HostListener,
  HostBinding,
} from '@angular/core';

@Directive({
  selector: '[didallaUpload]',
})
export class UploadDirective {
  @Output() fileDropped = new EventEmitter<any>();
  @HostBinding('style.background-color') public background = '#0D0F12';
  @HostBinding('style.opacity') public opacity = '1';

  @HostListener('dragover', ['$event']) onDragOver(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#9ecbec';
    this.opacity = '0.8'
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#0D0F12'
    this.opacity = '1'
  }

  @HostListener('drop', ['$event']) public ondrop(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#0D0F12'
    this.opacity = '1'
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files[0])
    }
  }
}
