import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Img } from '../img/img.type';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  profilePhotoConfig: Img = {
    image: '',
    alt: 'Profile Image',
    width: 120,
    height: 120,
    cover: false,
    contain: false,
    circle: false,
    padding: 0,
    background: 'unset',
  };

  @Input() title = '';
  @Input() subtitle = '';
  @Input() icon = '';
  @Input() button: any = null;
  @Output() emitClicked: any = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {
    this.profilePhotoConfig.image = 'assets/images/' + this.icon;
  }

  btnClicked() {
    this.emitClicked.emit(true);
  }
}
