import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HelperService } from 'src/app/core/helper/helper.service';

@Component({
  selector: 'app-filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.scss'],
})
export class FilterSelectComponent implements OnInit {
  @Input() disable: boolean = false;
  @Input() label: string = '';
  @Input() default: string = '';
  @Input() placeholder: string = '';
  @Input() display: string = 'name';
  @Input() selector: string = 'id';
  @Input() return: string = 'id';
  @Input() data: any[] = [];
  @Output() valueChange: any = new EventEmitter<any>();
  value: any = '';
  filteredOptions: any[] = [];
  validated = false;

  constructor(private helperService: HelperService) {}

  ngOnInit(): void {
    if (this.default) {
      let found = this.data.filter((element) => {
        return this.default == element[this.display];
      });
      if (found.length > 0) {
        this.value = found[0][this.display];
      }
    }
  }

  valueChanged(e: any) {
    this.validated = false;
    let newValue = e.target.value;
    this.filteredOptions = this._filter(newValue);
  }

  private _filter(search: string) {
    if (search === '') {
      return this.data;
    }
    return this.data.filter((item) =>
      item[this.display].toLowerCase().includes(String(search).toLowerCase())
    );
  }

  activateAutocomplete() {
    this.filteredOptions = this._filter(this.value);
  }

  validateProductValue() {
    if (this.validated == true) {
      return;
    }
    this.validated = true;
    setTimeout(() => {
      let found = this.data.filter((element) => {
        return this.value == element[this.display];
      });
      if (found.length == 0) {
        this.value = '';
        this.valueChange.emit(null);
        this.validated = false;
      } else if (found.length > 1) {
        this.valueChange.emit(null);
        this.value = '';
        this.validated = false;
        this.helperService.showInfo('multiple element detected', '');
      } else {
        this.valueChange.emit(found[0][this.selector]);
      }
    });
  }
}
