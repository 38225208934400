<div class="uploader">
  <input
    #fileInput
    (change)="fileProgress($event)"
    [accept]="type == 'image' ? '.jpg, .jpeg, .png' : ''"
    type="file"
    class="file-input"
  />
  <div>
    <button
    type="button"
    (click)="selectFile()"
    class="x-btn d-flex align-items-center"
    [ngClass]="{'x-btn-primary': !link, 'x-btn-link': link}"
  >
    <span>{{ label }}</span>
    <app-loading class="ml-1" *ngIf="uploading" color="#000"></app-loading>
  </button>
  <span *ngIf="type == 'image'" style="font-size: 0.8em">
    accepts: jpg, jpeg, png</span
  >
  </div>
</div>
<p *ngIf="uploading">{{ name }}</p>

<div class="cropmodal" *ngIf="showModal">
  <div class="overlay">
    <div>
      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="4 / 4"
        [resizeToWidth]="256"
        [cropperStaticWidth]="200"
        backgroundColor="#fff"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
    </div>

    <div class="buttons mt-3 text-center">
      <div>
        <button class="x-btn x-btn-primary" (click)="upload()">Upload</button>
      </div>
      <div>
        <button class="x-btn x-btn-danger" (click)="showModal = false">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
