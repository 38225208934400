import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-status-dsp',
  templateUrl: './status-dsp.component.html',
  styleUrls: ['./status-dsp.component.scss']
})
export class StatusDspComponent implements OnInit {

  @Input() status = ''
  constructor() { }

  ngOnInit(): void {
  }

}
