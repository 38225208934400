import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { HelperService } from 'src/app/core/helper/helper.service';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent {
  toggled = false;

  constructor(
    private helperService: HelperService,
    private authService: AuthService,
    private router: Router
  ) {}

  logout() {
    this.helperService
      .confirm(
        'Are you sure you want to logout?',
        'click yes to logout, you will be redirected back to login page.'
      )
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          this.authService.signOutApp();
          this.router.navigate(['sign-in']);
        }
      });
  }
}
