import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "num",
})
export class NumPipe implements PipeTransform {
  transform(value: number, ...args: any[]): string {
    if (value > 0) {
      return value.toLocaleString();
    } else if (value < 0) {
      return "(" + Math.abs(value).toLocaleString() + ")";
    } else {
      return "-";
    }
  }
}
