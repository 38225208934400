<div class="d-flex justify-content-between align-items-center p-4">
  <div>
    <h6>{{ data.title }}</h6>
  </div>
  <h5>
    <mat-icon
      class="text-dark cursor-pointer"
      (click)="dialogRef.close()"
      svgIcon="feather:x"
    ></mat-icon>
  </h5>
</div>

<mat-dialog-content>

  <p>{{data.description}}</p>

</mat-dialog-content>

<mat-dialog-actions align="end">
 <div class="p-3">
  <button class="x-btn x-btn-primary" (click)="yes()" mat-button [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
 </div>
</mat-dialog-actions>
