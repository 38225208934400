import { Injectable, PLATFORM_ID, Inject, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { isPlatformBrowser, Location } from "@angular/common";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ConfirmComponent } from "src/app/components/confirm/confirm.component";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { FormGroup } from "@angular/forms";
import { firstValueFrom, lastValueFrom, Observable } from "rxjs";
import {MatSnackBar, MatSnackBarModule} from '@angular/material/snack-bar';

@Injectable({
  providedIn: "root",
})
export class HelperService {

  constructor(
    private toastrService: ToastrService,
    @Inject(PLATFORM_ID) private platformId: any,
    private location: Location,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  header() {
    let headers = new HttpHeaders();
    // //headers.set('content-type', 'application/json');
    // headers.set('Accept', 'application/json');
    return headers;
  }

  getApiUrl() {
    return environment.api.url;
  }

  cutText(name: any, chars: number) {
    if (!name) {
      ("");
    }
    return name.length > chars ? name.substr(0, chars) + "..." : name;
  }

  public showSuccess(message: string, title: string) {
    // this.toastrService.success(message, title);
    this.snackBar.open(`${message}. ${title}`, '', {
      duration: 3000
    });
  }

  public showError(message: string, title: string) {
    // this.toastrService.error(message, title);
    this.snackBar.open(`${message}. ${title}`, '', {
      duration: 3000
    });
  }

  public showInfo(message: string, title: string) {
    // this.toastrService.info(message, title);
    this.snackBar.open(`${message}. ${title}`, '', {
      duration: 3000
    });
  }

  public showWarning(message: string, title: string) {
    // this.toastrService.warning(message, title);
    this.snackBar.open(`${message}. ${title}`, '', {
      duration: 3000,
    });
  }

  confirm(title: string, description: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: title,
      description: description,
    };
    dialogConfig.width = "500px";
    dialogConfig.autoFocus = false;
    let dialogRef = this.matDialog.open(ConfirmComponent, dialogConfig);
    return dialogRef;
  }

  loadModal(component: any, title: string, subtitle: string = "", data = {}) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { ...data, modal: { title, subtitle } };
    dialogConfig.width = "500px";
    dialogConfig.autoFocus = false;
    let dialogRef = this.matDialog.open(component, dialogConfig);
    return dialogRef;
  }

  clearStorage() {
    if (isPlatformBrowser(this.platformId)) {
      window.localStorage.clear();
    }
  }

  showTime(time: any) {
    let momentTime = moment().utc().set({ hour: time, minute: 0o0 });
    return moment(momentTime).format("LT");
  }

  showDateInWeek(dateTime: any) {
    const daysInWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const date = moment(dateTime);
    return daysInWeek[date.weekday()];
  }

  getError(error: any) {
    console
    switch (error.status) {
      case 0:
        return this.detectError(error.statusText);
      case 500:
        return this.detectError(error);
      case 404:
        return this.detectError(error);
      case 400:
        return this.detectError(error);
      case 422:
        return this.detectFormError(error);
      default:
        return this.detectError(error);
    }
  }

  detectError(error: any) {
    if (error.error) {
      this.showError("", error?.error?.message);
      return error?.error?.message;
    }
    return "An error has occured";
  }

  detectFormError(error: any) {
    if (error?.error) {
      this.showError("", error?.error?.message);
    }
  }

  getFullName(data: any, cut = false, length = 15) {
    if (cut) {
      return this.cutText(data?.firstName + " " + data?.lastName, length);
    }
    return data?.firstName + " " + data?.lastName;
  }

  getFile(url: string) {
    return url;
  }

  formatTime(value: any) {
    let date = new Date(value);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let day = date.getDate();
    let year = date.getFullYear();
    let month = months[date.getMonth()];
    let hours = ("0" + date.getHours()).substr(-2);
    let mins = ("0" + date.getMinutes()).substr(-2);

    return (
      month +
      " " +
      day +
      ", " +
      year +
      " " +
      hours +
      ":" +
      mins +
      (parseInt(hours) > 11 ? "PM" : "AM")
    );
  }

  formatDate(value: any) {
    if (!value) {
      return "";
    }

    // Months array
    let monthsArr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Convert timestamp to milliseconds
    let date = new Date(value);

    // Year
    let year = date.getFullYear();

    // Month
    let month = monthsArr[date.getMonth()];

    // Day
    let day = date.getDate();

    // Display date time in MM-dd-yyyy h:m:s format
    let convdataTime = day + " " + month + ", " + year;

    return convdataTime;
  }

  randomStr(minLength = 0, acc = ""): any {
    if (acc.length <= minLength) {
      const str = Math.random().toString(36).slice(2);
      return this.randomStr(minLength, acc.concat(str));
    }

    return acc.slice(0, minLength);
  }

  formGroupToFormData(formData: FormData, formGroup: FormGroup) {
    for (let key in formGroup.controls) {
      // console.log(formGroup.controls[key]);
      if (formGroup.controls[key] instanceof FormGroup) {
        this.formGroupToFormData(
          formData,
          formGroup.controls[key] as FormGroup
        );
      } else {
        formData.append(key, formGroup.controls[key].value);
      }
    }
  }

  generateRandomNumber(length = 6) {
    return (
      Math.random().toString(36).substring(2, length) +
      Math.random().toString(36).substring(2, length)
    );
  }

  generateCode() {
    return String(Math.floor(Math.random() * 900000) + 100000);
  }

  back() {
    this.location.back();
  }

  checkExpired(date: any) {
    if (!date) {
      return true;
    }
    const now = new Date();
    const expire = new Date(date);
    if (now.getTime() > expire.getTime()) {
      return true;
    }
    return false;
  }
}
