import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { UserService } from '../user/user.service';
import { HelperService } from '../helper/helper.service';

@Injectable()
export class AuthService {
  authenticated: boolean = false;
  redirectLink: any = '/dashboard';

  /**
   * Constructor
   */
  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
    private helperService: HelperService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  sendPasswordRecoveryEmail(email: any): Observable<any> {
    return this.httpClient.post(
      this.helperService.getApiUrl() + 'user/auth/forgot/password',
      { email }
    );
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(data: {
    password: string;
    email: string;
    code: string;
  }): Observable<any> {
    return this.httpClient.post(
      this.helperService.getApiUrl() + 'user/auth/reset/password',
      data
    );
  }

  /**
   * Reset password
   *
   * @param password
   */
  forgotPassword(data: {
    email: string;
    code: string;
  }): Observable<any> {
    return this.httpClient.post(
      this.helperService.getApiUrl() + 'user/auth/forgot/password',
      data
    ).pipe(
      switchMap((response: any) => {
        // Store the access token in the local storage
        this.accessToken = response.data.accessToken;

        // Return a new observable with the response
        return of(response);
      })
    );;
  }

  /**
   * change password
   *
   * @param data
   */
  changePassword(data: any): Observable<any> {
    return this.httpClient.post(
      this.helperService.getApiUrl() + 'settings/security/password',
      data
    );
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: any): Observable<any> {
    // Throw error, if the user is already logged in
    if (this.authenticated) {
      return throwError('User is already logged in.');
    }

    return this.httpClient
      .post(this.helperService.getApiUrl() + 'user/auth/login', credentials)
      .pipe(
        switchMap((response: any) => {
          // Store the access token in the local storage
          this.accessToken = response.data.accessToken;

          if (!response.data.twoFactorEnabled) {
            // Set the authenticated flag to true
            this.authenticated = true;
          }

          // Store the user on the user service
          this.userService.user = response.data;

          // Return a new observable with the response
          return of(response);
        })
      );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    return this.httpClient
      .delete(this.helperService.getApiUrl() + 'user/auth/logout')
      .pipe(
        switchMap((response: any) => {
          // Remove the access token from the local storage
          localStorage.removeItem('accessToken');

          // Set the authenticated flag to false
          this.authenticated = false;

          // Return the observable
          return of(true);
        })
      );
  }

  signOutApp(): Observable<any> {
    // Remove the access token from the local storage
    localStorage.removeItem('accessToken');

    // Set the authenticated flag to false
    this.authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(values: any): Observable<any> {
    return this.httpClient.post(
      this.helperService.getApiUrl() + 'user/auth/register',
      values
    );
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this.authenticated || this.accessToken) {
      this.authenticated = true;
      return of(true);
    }

    return of(false);

    // If the access token exists and it didn't expire, sign in using it
    //return this.signInUsingToken();
  }

  verifyEmail(email: string, code: string, login = true) {
    return this.httpClient
      .post(this.helperService.getApiUrl() + 'user/auth/verify', { email, code })
      .pipe(
        switchMap((response: any) => {
          if (login) {
            // Store the access token in the local storage
            this.accessToken = response.data.accessToken;

            // Set the authenticated flag to true
            this.authenticated = true;

            // Store the user on the user service
            this.userService.user = response.data;
          }

          // Return a new observable with the response
          return of(response);
        })
      );
  }

  sendEmailVerificationMail(email: string) {
    return this.httpClient.post(
      this.helperService.getApiUrl() + 'user/auth/verify/resend',
      { email }
    );
  }
}
