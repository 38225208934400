import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "cs",
})
export class CSPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    if (value == 'USDT') {
      return 'USD';
    } else {
      return value;
    }
  }
}
