import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HelperService } from 'src/app/core/helper/helper.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  id = this.helperService.randomStr(5)
  @Input() currentPage: number = 0
  @Input() perPage: number = 0
  @Input() total: number = 0
  @Input()  data: any = []
  @Output() pageChange : any = new EventEmitter<number>();

  constructor(private helperService: HelperService) { }

  ngOnInit(): void {
  }

  switchPage(p : number){
    this.pageChange.emit(p)
  }

}
