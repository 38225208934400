import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingComponent } from './components/loading/loading.component';
import { ToastrModule } from 'ngx-toastr';
import { MaterialModule } from './core/material/material.module';
import { IconsModule } from './core/icons/icons.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { ImgComponent } from './components/img/img.component';
import { CodeInputModule } from 'angular-code-input';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PaginationComponent } from './components/pagination/pagination.component';
import { NumPipe } from './pipes/num.pipe';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { TransactionsComponent } from './components/transactions/transactions.component';
import { FilterSelectComponent } from './components/filter-select/filter-select.component';
import { StatusDspComponent } from './components/status-dsp/status-dsp.component';
import { RouterModule } from '@angular/router';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '../environments/environment';
import { CSPipe } from './pipes/cs.pipe';
import { DragDropFileUploadComponent } from './components/drag-drop-file-upload/drag-drop-file-upload.component';
import { UploadDirective } from './directives/upload.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    ImageCropperModule,
    CodeInputModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
    }),
    IconsModule,
    NgbModule,
    NgxPaginationModule,
    RouterModule,
    NgxStripeModule.forRoot(environment.payment.stripe.public_key)
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingComponent,
    ToastrModule,
    MaterialModule,
    ImageCropperModule,
    FileUploadComponent,
    ImgComponent,
    NgbModule,
    CodeInputModule,
    PageLoaderComponent,
    NotFoundComponent,
    NgxPaginationModule,
    PaginationComponent,
    NumPipe,
    CSPipe,
    ConfirmComponent,
    TransactionsComponent,
    FilterSelectComponent,
    StatusDspComponent,
    NgxStripeModule,
    DragDropFileUploadComponent,
    UploadDirective,
  ],
  declarations: [
    ConfirmComponent,
    LoadingComponent,
    FileUploadComponent,
    ImgComponent,
    PageLoaderComponent,
    NotFoundComponent,
    PaginationComponent,
    NumPipe,
    CSPipe,
    TransactionsComponent,
    FilterSelectComponent,
    StatusDspComponent,
    DragDropFileUploadComponent,
    UploadDirective,
  ],
})
export class SharedModule {}
