import { NgModule } from '@angular/core';
import {
  ExtraOptions,
  PreloadAllModules,
  RouterModule,
  Routes,
} from '@angular/router';
import { LayoutComponent } from './layout/layout/layout.component';
import { AuthGuard } from './core/auth/guards/auth.guard';
import { NoAuthGuard } from './core/auth/guards/noAuth.guard';
import { InitialDataResolver } from './app.resolvers';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'sign-in' },

  {
    path: '',
    canActivate: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      showPageHeader: false,
      showPageFooter: false,
    },
    children: [
      { path: 'sign-in', loadChildren: () => import('./modules/auth/sign-in/sign-in.module').then(m => m.SignInModule) },
      { path: 'reset-password/:email/:code', loadChildren: () => import('./modules/auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
      { path: 'forgot-password', loadChildren: () => import('./modules/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
      { path: 'confirm-email/:email/:type', loadChildren: () => import('./modules/auth/confirmation-required/confirmation-required.module').then(m => m.ConfirmationRequiredModule) },
      { path: 'register', loadChildren: () => import('./modules/auth/register/register.module').then(m => m.RegisterModule) },
    ]
  },

  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    resolve    : {
      initialData: InitialDataResolver,
    },
    data: {
      showPageHeader: true,
      showPageFooter: true,
    },
    children: [
      { path: 'home', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
      { path: 'transactions', loadChildren: () => import('./modules/transaction/transaction.module').then(m => m.TransactionModule) },
      { path: 'account', loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule) },
      { path: 'receipients', loadChildren: () => import('./modules/receipients/receipients.module').then(m => m.ReceipientsModule) },
      { path: 'send', loadChildren: () => import('./modules/send/send.module').then(m => m.SendModule) },
    ]
  },
];

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerConfig)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
