import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { HelperService } from 'src/app/core/helper/helper.service';
import { UploadService } from './upload.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  showModal: boolean = false;
  fileData: File = {} as File;
  name: string = '';
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef = {} as ElementRef;
  @Input() label : string = '';
  @Output() uploaded : any = new EventEmitter<any>();
  @Input() color = 'primary';
  @Input() type : any = 'file'
  @Input() link = false;
  @Input() uploading : boolean = false;


  croppedImage: any = '';
  imageChangedEvent: any = '';

  constructor(
    public helperService: HelperService,
    public uploadService: UploadService,
  ) { }

  ngOnInit(): void {
  }

  selectFile(){
    this.fileInput.nativeElement.click()
  }

  fileProgress(fileInput: any) {
    this.name = fileInput.target.files[0]?.name;
    if(this.type == 'image'){
      this.showModal = true;
      this.imageChangedEvent = fileInput;
    }
    else{
      this.fileData = <File>fileInput.target.files[0];
      this.preview();
    }
  }

  preview() {
    if (this.fileData.size > 2000000) {
      this.helperService.showError('Size exceed 2mb', "Upload Error");
      return;
    }
    this.startUpload();
  }

  startUpload() {
    this.uploading = true;
    const form = new FormData();
    form.append('file', this.fileData);
    this.uploadService.upload(form).subscribe({
      next: (data: any) => {
        this.uploading = false;
        this.uploaded.emit({ file: this.fileData, url: data.data });
      },
    });
  }

  imageCropped(event: ImageCroppedEvent) {
    this.dataUrlToFile(event.base64, this.helperService.randomStr(6)+'.png').then((data) => {
      this.fileData = data;
    })
  }

  imageLoaded() {
    //image loaded
  }

  cropperReady() {
      // cropper ready
  }

  loadImageFailed() {
      this.helperService.showError('Invalid Image Selected', 'Error');
  }

  upload(){
    this.showModal = false;
    this.startUpload();
  }

  async dataUrlToFile(dataUrl: any, fileName: string): Promise<File> {

    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, { type: 'image/png' });
}

}
